import React from 'react';
import {Box, Button, Paper, Typography, createTheme, ThemeProvider} from "@mui/material"
import {Link} from "react-router-dom"
import { styled } from "@mui/material/styles"



function Blog(props) {

    const customTheme = createTheme({
        palette: {
            primary : {
                main: "#64ffda"
            }
        }
    
      });
  

    return(

        <Box sx={{display: "flex",  justifyContent:"center", alignItems:"center", height:"60vh"}}>
            <Paper sx={{backgroundColor: "#112240", width:"70%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                <Typography sx={{ marginBottom: "1rem", padding:"1rem"}} color="#64ffda">
                    Page is in re-construction right now. 
                    Updating my blogging library
                </Typography>
            

            <Link style={{textDecoration: "none"}} to="/"> 
                <ThemeProvider theme={customTheme}>
                    <Button variant="outlined" sx={{marginBottom: "1rem"}} >
                        Home
                    </Button>
                </ThemeProvider>
               
            </Link>
            </Paper>
        </Box>
        
        
    )
} export default Blog;