import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { Box, createTheme, Typography} from '@mui/material';
import GamesIcon from '@mui/icons-material/Games';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

function About(props) {

    const textStyle = createTheme({
        palette: {
            text: {
                secondary: "#8892B0",
                listing: "#64ffda"
            }
        }
    })

    const TheLine = styled("li")({
        padding: " 0.4rem"
    })

    
    return (
    <Box sx={{display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems:"center", height: "70%" , marginBottom:"1rem" ,}}>
        <Box sx={{ display: "flex", justifyContent: "center" , flexDirection: "column", width: {xs: "100%", sm:"80%" ,  md: "70%", lg: "50%"}}}  >
            <Box sx={{display: "flex", alignItems: "center"}}>
                <GamesIcon  sx={{marginRight: "1rem", color: "#64ffda"}}/>
                <Typography sx={{color: "#64ffda", fontWeight:800}} variant="h2">
                    About Me
                </Typography>
                <GamesIcon sx={{marginLeft: "1rem" , color: "#64ffda"}}/>
            </Box>
            

            <ThemeProvider theme={textStyle} >
                <Typography sx={{marginTop: "1rem"}} color={textStyle.palette.text.secondary} variant="body1">
                Hello! My name is John and I love creating applications that live on the internet. My interest in web development 
                was reignited in 2014 when I discovered the Ruby on Rails framework- Since then I developed multiple web applications from the ground up.
                </Typography>

                <Typography sx={{marginTop: "1rem"}} color={textStyle.palette.text.secondary} variant="body1">
                Fast-forward to today. I’ve had the privilege to participate in multiple projects starting with War of Sigmar as
                 a Ruby developer, then Warzone-Weapons and more recently Battle Tactics Assistant both as full stack developer using Ruby and ReactJs. 
                 My main focus these days is building accessible, functional and fast web applications for a variety of clients.
                </Typography>
           
                <Typography variant="body1" color={textStyle.palette.text.secondary} sx={{fontWeight: "800", marginTop: "1rem"}}>
                Here are a few technologies I’ve been working with recently:
                </Typography>

                <Box color={textStyle.palette.text.listing} sx={{display: "flex", justifyContent: "left", fontWeight: "300"}}>
                   
                    <ul>
                        <TheLine>JavaScript</TheLine>
                        <TheLine>ReactJs</TheLine>
                        <TheLine>Material UI</TheLine>
                    </ul>

                    <ul>
                        <TheLine>Ruby on Rails</TheLine>
                        <TheLine>Ruby</TheLine>
                        <TheLine>RSpec</TheLine>
                    </ul>

                   
                </Box>
            </ThemeProvider>
        
        </Box>
        <Box>
            <Card sx={{display:"flex", justifyContent:"center", border: " 3px solid #64ffda",}}>
                <CardMedia 
                    sx={{padding: "0.5rem", backgroundColor:"#0a192f" }}
                    component="img"
                    alt="john photo"
                    height="300"
                    image="./john2.png"
                />
            </Card>
        </Box>
    </Box>
    )
} export default About;