import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import {useTheme, useMediaQuery} from '@mui/material'
import LinkIcon from '@mui/icons-material/Link';

function WarOfSigmar(props) {
    


    const itemData = [
        {img: "/images/wos/wos1.jpeg" ,
        title: "war of sigmar 1"},

        {img: "/images/wos/wos2.jpeg" ,
            title: "war of sigmar 2"},
        
        {img: "/images/wos/wos3.jpeg" ,
            title: "war of sigmar 3"},

        {img: "/images/wos/wos4.jpeg" ,
            title: "war of sigmar 4"},
            
        {img: "/images/wos/wos5.jpeg" ,
            title: "war of sigmar 5"},
            
    ]

 
    const themeForBreakpoints = useTheme();
    const myCols = useMediaQuery(themeForBreakpoints.breakpoints.up('sm')) ? 2 : 1  ;

    return(
        <Box >

            <Typography sx={{textAlign:"center", color: "#64ffda", fontWeight:500, fontSize:{xs: "200%"}}} variant="h3">
                        War Of Sigmar 
            </Typography>
            
            <Box  sx={{display:"flex", flexWrap: "wrap", justifyContent: "center", alignItems: "flex-start", marginTop:"3rem", marginBottom: "1.5rem"}} >
               

                <Box sx={{  display:"flex", flexDirection:"column", justifyContent: "flex-start", width: {xs : "100%", sm: "70%", md: "70%" , lg: "50%"}}} >
                

                    <Typography sx={{color: "#8892b0", fontWeight:600, marginTop: "2rem"}}>
                        Stack: the App is developed in Ruby using the Ruby on Rails frameworks. I used TDD and Control version with Git. The back-end is in Ruby but the front is made with Bootstrap using a mix of HTML CSS and JS (Jquery).
                        I am planning to update the front with ReactJs and transform the back end into a full API but the lack of time has not allowed me to do it yet.      
                    </Typography>

                    <Typography sx={{color: "#8892b0", fontWeight:600, marginTop: "2rem"}}>
                        War of Sigmar is a web application that I started developing in 2015 with 2 others associates. We are all wargamer (like Warhammer) 
                        and we wanted to combine our love of these games and a web application. The app was about serving helpful data about different games to increase the player experiences and results. 
                        In detail, the first version of the app was calculating the real points cost of each unit in the Age of Sigmar game. 
                    </Typography>   
                    <Typography sx={{color: "#8892b0", fontWeight:600, marginTop: "2rem"}}>
                        Then as Games Workshop released a points system for AOS we upgraded the app to give a mathematical analysis of the relative points cost and units performance in-game Meta. At the same time, 
                        I added a blogging interface to the App. Since then for juridic reasons, the Points Analysis is not accessible to the public and only the blog remains.
                    </Typography>
           
                    <Typography sx={{color: "#8892b0", fontWeight:600, marginTop: "2rem"}}>
                        The Blogging section of the App is still working fine and serve 1.5 million pages a month. I myself still write content for the blog to keep the flame alive.
                    </Typography>
                </Box>
                
                <Box sx={{ marginTop:"1rem",  width: {xs : "100%", sm: "70%", md: "70%" , lg: "50%"} }}>
                    <ImageList sx={{ width: "100%", height: 450, border : "solid 1px #64ffda" }} cols={myCols} rowHeight={300}>
                        {itemData.map((item) => (
                            <ImageListItem sx={{marginBottom:"1 rem"}} key={item.img}>
                                <img
                                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.title}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
            
            </Box>

            <Link underline="hover"  sx={{marginTop: "5rem", textAlign:"center", color: "#64ffda", fontWeight:500, display:"flex", alignItems:"center"}} href="https://war-of-sigmar.herokuapp.com/"> <LinkIcon/>   War Of Sigmar App</Link>
        </Box>
    )
} export default WarOfSigmar;