import React, {useState} from 'react';
import { Box, Tabs, Tab,  createTheme, } from '@mui/material';
import TabPanel from "./TabPanel"
import WarOfSigmar from "./WarOfSigmar"
import WarzoneWeapons from "./WarzoneWeapons"
import BattletomeAssistant from "./BattleTomeAssistant"
import OtherProjects from "./OtherProjects"
import Abstract from "./Abstract"
import { ThemeProvider } from '@emotion/react';

function Experience(props) {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


   

    const tabsStyle = createTheme({
        components :{
           
            MuiTabs : {               
                styleOverrides: {
                    scrollButtons : {                   
                        color: "#64ffda",                       
                    }
                }
            }
        },
        palette : {
            primary : {
                main: "#64ffda"
            } , 
            secondary : {
                main: "#2de036"
            }
        }
    })
    
   return (
    <Box sx={{ width: '100%' }}>
        <Box >
            <ThemeProvider theme={tabsStyle}>

                <Tabs 
                    allowScrollButtonsMobile={true}
                      textColor="primary" 
                      indicatorColor="primary"  
                      value={value} 
                      onChange={handleChange} 
                      aria-label="Stuff i did" 
                      variant="scrollable"
                      scrollButtons= "auto">
                    <Tab sx={{color: "#8892b0"}} label="Introduction"  />
                    <Tab sx={{color: "#8892b0"}} label="War of Sigmar"  />
                    <Tab sx={{color: "#8892b0"}} label="Warzone Weapons"  />
                    <Tab sx={{color: "#8892b0"}} label="Battle Tactics"  />
                    <Tab sx={{color: "#8892b0"}} label="Other Projects"  />
                </Tabs>
            </ThemeProvider>
        </Box>
        <TabPanel value={value} index={0}>
            <Abstract/>
        </TabPanel>

        <TabPanel value={value} index={1}>
           <WarOfSigmar />
        </TabPanel>

        <TabPanel value={value} index={2}>
            <WarzoneWeapons />
        </TabPanel>

        <TabPanel value={value} index={3}>
                <BattletomeAssistant />
        </TabPanel>

        <TabPanel value={value} index={4}>
                <OtherProjects />
        </TabPanel>
  </Box>
   )
    
}
export default Experience;
