import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {createTheme, ThemeProvider} from "@mui/material"

function DialogCv(props) {

    const { open, setOpen } = props


    const theTheme = createTheme({
        palette : {
          secondary : {
            main: "#64ffda"
          }
        }
        ,
        components: {
          // Name of the component
          MuiDialog: {
            styleOverrides: {
              // Name of the slot
              paper: {
                // Some CSS
                backgroundColor: "#0a192f",
                border: "1px solid #64ffda",
                elevation: 9,
                
              },
            },
          },
        },
      });
  

  const handleClose = () => {
    setOpen(false);
  };

    return(
        <div>  

    <ThemeProvider theme={theTheme}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="choose cv language"
        aria-describedby="alert dialog cv"
      >
        <DialogContent >
          <DialogContentText textAlign="center" variant="button" color="secondary" id="alert-dialog-description">
            Download my resume/cv 
          </DialogContentText  >
        </DialogContent>
        <DialogActions sx={{display: "flex", justifyContent:"center"}}>
         <a style={{margin: "1rem",  textDecoration: "none"}} href="./jzfr.pdf"> <Button  variant="outlined" color="secondary" onClick={handleClose}>Francais</Button> </a>
         <a style={{margin: "1rem", textDecoration: "none"}} href="./jzeng.pdf"> <Button variant="outlined" color="secondary" onClick={handleClose} autoFocus> English </Button></a>
        </DialogActions>
      </Dialog>
    </ThemeProvider>

    </div>
    )
} export default DialogCv;