import { Box, Typography, Link} from '@mui/material';
import React from 'react';
// import dogs1 from "./../images/dogs/dogs1.jpeg"
// import dogs2 from "./../images/dogs/dogs2.jpeg"
// import dogs3 from "./../images/dogs/dogs3.jpeg"
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import LinkIcon from '@mui/icons-material/Link';

function Dogsadoptions(props) {

    
    

       
    const itemData = [
        {img: "/images/dogs/dogs1.jpeg" ,
        title: "Dogs 1"},

        {img: "/images/dogs/dogs2.jpeg" ,
            title: "Dogs 2"},

        {img: "/images/dogs/dogs3.jpeg" ,
            title: "Dogs  3"},
             
    ]


    return(
        
         <Box  sx={{ display:"flex", flexWrap: "wrap", justifyContent: "center", alignItems: "flex-start", marginTop:"3rem", marginBottom: "1.5rem"}} >

            <Typography variant="h4" sx={{color: "#64ffda", fontWeight:600, marginTop: "2rem"}}>
            Dogs Adoption front-end 
            </Typography>  

            <Box sx={{  display:"flex", flexDirection:"column", justifyContent: "flex-start", width: {xs : "100%", sm: "90%", md: "90%" , lg: "80%"}}} >
                
                

                <Typography variant="block1" sx={{color: "#8892b0", fontWeight:600, marginTop: "2rem"}}>
                ReactJs Web Application. Fetch a list of dogs up for Adoption and render a list and a navigation dynamically. The project was a charitative one where good samaritans that were 
                taking care of dogs could offer them up for adoption. Sadly the api side of the project was turned off.

                On the technical point it was interesting to try the new React Router Dom.
                </Typography> 


                <Link underline="hover"  sx={{ textAlign:"center", color: "#64ffda", fontWeight:500, display:"flex", alignItems:"center", marginTop: "2rem"}} href="https://hidden-wave-83721.herokuapp.com/">
                     <LinkIcon/>Dogs Adoptions front </Link>
               
                <Typography variant="caption" sx={{color: "#8892b0", fontWeight:600}}>

                (ps : The app is in sleep mode and need a good 30 secondes to wake up)

                </Typography>
            </Box>
                
            <Box sx={{ marginTop:"1rem",  width: {xs : "100%", sm: "95%", md: "95%" , lg: "95%"} }}>
                <ImageList variant="standard" cols={3}  rowHeight={300}>
                    {itemData.map((item) => (
                        <ImageListItem sx={{marginBottom:"1 rem" , border : "solid 1px #64ffda"}} key={item.img}>
                            <img
                                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt={item.title}
                                loading="lazy"
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>   

       

        </Box>

       
    
    )
} export default Dogsadoptions;