import { Box, Grid} from '@mui/material';
import React from 'react';
import Navbar from "./Navbar"
import {Routes, Route} from "react-router-dom"
import Home from "./Home"
import About from "./About"
import Experience from './Experience';
import Projects from "./Projects"
import Blog from "./Blog"
function PortfolioApp(props) {
    
    return(
        
         <Box sx={{backgroundColor: "#0a192f", height: "100vh", backgroundSize: "100%" }}>

             <Navbar/>
             <Grid container sx={{ height: "100%" , display: "flex", justifyContent: "center", marginTop:"3rem" }}>
                <Grid item xs={11} lg={9.3}>

                     <Routes>
                         <Route path="/" element={<Home /> } /> 
                         <Route path="/about" element={<About />} /> 
                         <Route path="/experience" element={<Experience />} /> 
                         <Route path="/projects" element={<Projects />} /> 
                         <Route path="/blog" element={<Blog />} /> 

                     </Routes>
                </Grid>
             </Grid>

           
            
         </Box>

        
        )
}

export default PortfolioApp;