import { Box,Typography, Link} from '@mui/material';
import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import LinkIcon from '@mui/icons-material/Link';

function LightsOut(props) {

    
    

       
    const itemData = [
        {img: "/images/lightsout/lightsout1.jpeg" ,
        title: "lights out game 1"},

        {img: "/images/lightsout/lightsout2.jpeg" ,
            title: "lights out game 2"},

       
             
    ]


    return(
        
         <Box  sx={{ display:"flex", flexWrap: "wrap", justifyContent: "center", alignItems: "flex-start", marginTop:"3rem", marginBottom: "1.5rem"}} >

            <Typography  textAlign="center"  variant="h4" sx={{color: "#64ffda", fontWeight:600, marginTop: "2rem", width:"70%"}}>
            Lights Out Mini Game
            </Typography>  

            <Box sx={{  display:"flex", flexDirection:"column", justifyContent: "flex-start", width: {xs : "100%", sm: "90%", md: "90%" , lg: "80%"}}} >
                
               

                <Typography variant="block1" sx={{color: "#8892b0", fontWeight:600, marginTop: "2rem"}}>
                    Old Javascript game made with reactJS. Sorry if I hurt your eyes with the design, I was going through a Disco CSS phase ^^    
                </Typography> 


                <Link underline="hover"  sx={{ textAlign:"center", color: "#64ffda", fontWeight:500, display:"flex", alignItems:"center", marginTop: "2rem"}} href="https://lightsoutjohn.herokuapp.com/">
                     <LinkIcon/>Lights Out Mini Game </Link>
               
                <Typography variant="caption" sx={{color: "#8892b0", fontWeight:600}}>

                    (ps : The app is in sleep mode and need a good 30 secondes to wake up, good luck if you try to win ^^)

                </Typography>
            </Box>
                
            <Box sx={{ marginTop:"1rem",  width: {xs : "100%", sm: "95%", md: "95%" , lg: "95%"} }}>
                <ImageList variant="standard" cols={2}  rowHeight={400}>
                    {itemData.map((item) => (
                        <ImageListItem sx={{marginBottom:"1 rem" , border : "solid 1px #64ffda"}} key={item.img}>
                            <img
                                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt={item.title}
                                loading="lazy"
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>   

           
        </Box>

       
    
    )
} export default LightsOut;