import { Box, Stack, Typography , Paper} from '@mui/material';
import { styled } from "@mui/material/styles"
import React from 'react';

function Abstract(props) {

    const Item = styled(Paper)( ({ theme }) => ({
        padding: "1rem",
        backgroundColor: "#112240",
        color: "#8892B0",
        listing: "#64ffda"
    }))

    const TypoTitle = styled(Typography)( ({theme}) => ({       
            color: "#64ffda",        
    }))

    const DateTypo = styled("span")({
        fontSize: "0.8rem"
    })

  
    console.log(TypoTitle['_emotion_Base'])
    return(
        <Box sx={{display:"flex", justifyContent:"center"}}>
            <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center" ,  width: {xs: "100%", sm:"80%" ,  md: "70%", lg: "50%"}}}>
                <TypoTitle textAlign="center" variant="h3" sx={{fontWeight: 800}}>
                    Summary
                </TypoTitle>

                <Typography textAlign="start" sx={{color :"#8892B0", margin:"1rem 0 "}} variant="h5" >
                     Summary of my works since 2015
                </Typography>

                <Stack  spacing={4}>

                    <Item>
                        <TypoTitle variant="h5" >
                            Battle Tactics Assistant <DateTypo> (2022-?)</DateTypo> 
                        </TypoTitle>
                        <Typography sx={{marginTop: "1rem"}} variant="body1">
                        Personnal Project that i am develloping right now. The Application is made in Javascript with the ReactJs library using the most recent evolution like Hooks, Material UI v5, React Router Dom v6.
                        Still early in existence but the review are promising and a Korean version of the app is nearly complete. 
                        </Typography>
                    </Item>


                    <Item>
                        <TypoTitle variant="h5">
                            Warzone Weapons <DateTypo> (2020-2021)</DateTypo> 
                        </TypoTitle>
                        <Typography sx={{marginTop: "1rem"}} variant="body1">
                            For 2 years (2019-2021) I worked as a full stack developer and was an associate to the project(from the star). Designed the Api,
                            developed it in Ruby with the Ruby on rails framework. The back is connected to a Front End is a ReactJs. Also developed complex calculus function for the data rendering.
                            Used TDD and Control version with Git during the development. Also managed the deployment on Heroku and the hosting on AWS Cloud.
                        </Typography>
                    </Item>

                    <Item>
                        <TypoTitle variant="h5" >
                            Freelance devellopement <DateTypo> (2019-2020)</DateTypo> 
                        </TypoTitle>
                        <Typography sx={{marginTop: "1rem"}} variant="body1">
                            For one year I developed multiple applications in ReactJs or Ruby on rails. 
                            Also Started to focus more and more on the Mern Stack with MongoDB, Express.js ReactJs and Node. 
                        </Typography>
                    </Item>

                    <Item>
                        <TypoTitle variant="h5">
                            War of sigmar <DateTypo> (2015-2019)</DateTypo>                                
                        </TypoTitle>
                        <Typography sx={{marginTop: "1rem"}} variant="body1">
                            For Nearly 4 years (2015-2019) I worked as a full-stack developer on this project. Also became an associate during the development.
                            It was the first big application i worked on. The Back-end is developed in Ruby using the Ruby on Rails framework. 
                            The front is made with Bootstrap using a mix of HTML CSS and Javascript(Jquery). Used TDD and Control version with Git during the development.
                            Also managed the deployment on Heroku and the hosting on AWS
                        </Typography>
                    </Item>

                    
                </Stack>
            </Box>
        </Box>
        
    )

} export default Abstract;