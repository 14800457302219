import { Box, useTheme, Link, Typography } from '@mui/material';
import React from 'react';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import useMediaQuery from '@mui/material/useMediaQuery';
import LinkIcon from '@mui/icons-material/Link';

function BattletomeAssistant(props) {
    

    const itemData = [
        {img: "/images/btassistant/btassistant.jpeg" ,
        title: "war of sigmar 1"},

        {img: "/images/btassistant/btassistant2.jpeg" ,
            title: "war of sigmar 3"},

        {img: "/images/btassistant/btassistant3.jpeg" ,
            title: "war of sigmar 2"},
       
    ]

   

    const themeForBreakpoints = useTheme();
    const myCols = useMediaQuery(themeForBreakpoints.breakpoints.up('sm')) ? 2 : 1  ;



    return(
        <Box >

            <Typography sx={{textAlign:"center", color: "#64ffda", fontWeight:500, fontSize:{xs: "200%"}}} variant="h3">
                        Battle Tactics Assistants
            </Typography>
            
            <Box  sx={{display:"flex", flexWrap: "wrap", justifyContent: "center", alignItems: "flex-start", marginTop:"3rem", marginBottom: "1.5rem"}} >
               

                <Box sx={{  display:"flex", flexDirection:"column", justifyContent: "flex-start", width: {xs : "100%", sm: "70%", md: "70%" , lg: "50%"}}} >
                

                    <Typography sx={{color: "#8892b0", fontWeight:600, marginTop: "2rem"}}>
                        Stack :The app is made with React.js with the novelty of being 100% stateless and using Local storage for data remanence.
                        A Node API is in the making to collect games result for future statistical data. Library used are  : Material UI 5, React Router Dom 6
                       
                    </Typography>

                    <Typography sx={{color: "#8892b0", fontWeight:600, marginTop: "2rem"}}>
                        Battle Tactics Assistants is a web app that I am working on currently. The goal is to track the Objectives cleared during a game of Age of Sigmar(it's a wargame). 
                        While the application is still in development, the first impressions are very positive. 
                        Most users are reporting a big improvement in their gameplay experience and games result.
                    </Typography>   
                    <Typography sx={{color: "#8892b0", fontWeight:600, marginTop: "2rem"}}>
                        The app is primarily designed to be used on phones, so while it may look weird on a 1440p+ resolution, the focus is mobile usage. 
                        Also, the app has to be fast and easy to use, I don't want to create a login unless it's absolutely necessary. 
                        Most data remanence requirements are limited to the duration of a game (for now) so the app stock the result on the LocalStorage of the device.
                    </Typography>

                   


                    <Typography sx={{color: "#8892b0", fontWeight:600, marginTop: "2rem"}}>
                        I plan to keep the app up to date with the game, add Primary objectives tracking, statistical result by faction and battle tactics pick rate.                
                    </Typography>
                </Box>
                
                <Box sx={{ marginTop:"1rem",  width: {xs : "100%", sm: "70%", md: "70%" , lg: "49%"} }}>
                    <ImageList sx={{ width: "100%", height: 600 }} cols={myCols}  rowHeight="auto">
                        {itemData.map((item) => (
                            <ImageListItem sx={{marginBottom:"1 rem" , border : "solid 1px #64ffda"}} key={item.img}>
                                <img
                                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.title}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
            
            </Box>

            <Link underline="hover"  sx={{marginTop: "5rem", textAlign:"center", color: "#64ffda", fontWeight:500, display:"flex", alignItems:"center"}} href="https://battletactics.herokuapp.com/"> <LinkIcon/> Battle Tactics Assistant </Link>
        </Box>
    )
} export default BattletomeAssistant;