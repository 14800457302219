import React from 'react';
import { Box, Button,Typography , createTheme, ThemeProvider, Link} from '@mui/material';

function Home(props) {

    const customTheme = createTheme({
        palette: {
            primary : {
                main: "#64ffda"
            }
        }
    
      });

    const fontSizeTheme = createTheme();

    fontSizeTheme.typography.h2 = {

            [fontSizeTheme.breakpoints.up('xs')]: {
                fontSize: '2rem',
                },

            [fontSizeTheme.breakpoints.up('sm')]: {
                fontSize: '2.5rem',
                    },

            [fontSizeTheme.breakpoints.up('md')]: {
                fontSize: '3.5rem',
            },

            [fontSizeTheme.breakpoints.up('lg')]: {
                fontSize: '4rem',
                },
            
    };

 
    fontSizeTheme.typography.h3 =  {

        [fontSizeTheme.breakpoints.up('xs')]: {
            fontSize: '1.5rem',
            },

        [fontSizeTheme.breakpoints.up('sm')]: {
            fontSize: '2rem',
            },

        [fontSizeTheme.breakpoints.up('md')]: {
            fontSize: '3rem',
            },

        [fontSizeTheme.breakpoints.up('lg')]: {
            fontSize: '3.5rem',
            },
        
    };

    const test = {
        [fontSizeTheme.breakpoints.up('xs')]: {
            fontSize: '0.9rem',
            },

        [fontSizeTheme.breakpoints.up('sm')]: {
            fontSize: '1rem',
                },

        [fontSizeTheme.breakpoints.up('md')]: {
            fontSize: '1.25rem',
        },

        [fontSizeTheme.breakpoints.up('lg')]: {
            fontSize: '1.5rem',
            },
        
    }

    fontSizeTheme.typography.body1 = {...fontSizeTheme.typography.h3, ...test}

    
    return (

    <Box  sx={{ height:"75%", display:"flex", justifyContent:"center", alignItems:"center",}}>
        <Box sx={{width: {xs: "100%", md: "70%", lg:"60%"}}}>
            <Typography variant="overline" sx={{color: "#64ffda", fontWeight:800}}>
                    Hello, my name is 
            </Typography>
            <ThemeProvider theme={fontSizeTheme}>
                <Typography variant="h2" sx={{color: "#ccd6f6", fontWeight:700, margin: "0.5rem 0"}}>
                    John Zermati.
                </Typography>
            

            <Typography variant="h3" sx={{color: "#8892b0", fontWeight:600, margin: "0.5rem 0"}}>
                I build things for the web.
            </Typography>

            <Box>
                <Typography variant="body1"    sx={{color: "#8892b0", marginTop: "1rem", maxWidth: { xs: '100%', md: '80%', lg: '70%' }, fontWeight:600 }}>
                I am a full-stack developer specialising in building modern web applications. 
                I can build anything from a Restfull Api to complex Front Logic. 
                Currently Focusing on the JS stack with ReactJs and Node. I also love Ruby and RoR for back-end wor
                </Typography>
            </Box>
                <ThemeProvider theme={customTheme}>
                    <Link underline="none" href="mailto:john.zermati@gmail.com"> <Button sx={{marginTop: "2rem"}} variant="outlined">Get in Touch </Button></Link>
                </ThemeProvider>
            </ThemeProvider>

        </Box>
       
        
    </Box>

    )
} export default Home;