import PortfolioApp from "./PortfolioApp"


function App() {
  return (
    
    <PortfolioApp/>
  );
}

export default App;
