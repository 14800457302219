import { Divider, List, ListItem } from '@mui/material';
import React from 'react';
import ColorsPalette from './OtherProject/ColorsPalette';
import Dogsadoptions from './OtherProject/DogsAdoptions';
import LightsOut from './OtherProject/LightsOut';


function OtherProjects(props) {

   const dividerColor = "#64ffda"
    return(
        <List>

            <ListItem>
                <ColorsPalette />
            </ListItem>
                <Divider  color={dividerColor}  />
            <ListItem>
                <LightsOut />

            </ListItem>

            <Divider   color={dividerColor}  />

 
            <ListItem>
            <Dogsadoptions />

            </ListItem>
            <Divider   color={dividerColor}  />



        </List>
    )

} export default OtherProjects