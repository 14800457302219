import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import FaceIcon from '@mui/icons-material/Face';
import { Link } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import DialogCv from './DialogCv'


function Navbar(props) {

        const pages = [
          {title : 'About me', adress: "about"}, 
          {title : 'Experience', adress: "experience"},
          {title : 'Projects', adress: "projects"},
          {title : 'Blog', adress: "blog"},
        ];

        const settings = [ 
          {name: "Mail" , link: "mailto:john.zermati@gmail.com"},
          {name: "Git" , link: "https://bitbucket.org/bobthebomb/"},
        ]
    
        const [anchorElNav, setAnchorElNav] = useState(null);
        const [anchorElUser, setAnchorElUser] = useState(null);      
        const [open, setOpen] = useState(false);

        const handleOpenNavMenu = (event) => {
          setAnchorElNav(event.currentTarget);
        };
        const handleOpenUserMenu = (event) => {
          setAnchorElUser(event.currentTarget);
        };
      
        const handleCloseNavMenu = () => {
          setAnchorElNav(null);
        };
      
        const handleCloseUserMenu = () => {
          setAnchorElUser(null);
        };

        const handleCloseResume = () => {
            setAnchorElUser(null)
            setOpen(true)           
        }

        const theTheme = createTheme({
          palette : {
            secondary : {
              main: "#64ffda"
            }
          }
          ,
          components: {
            // Name of the component
            MuiMenu: {
              styleOverrides: {
                // Name of the slot
                list: {
                  // Some CSS
                  backgroundColor: "#0a192f",
                  border: "1px solid #64ffda",
                  elevation: 9,
                  
                },
              },
            },
          },
        });

        
     
    
    return(


    <AppBar sx={{backgroundColor: "#0a192f" }} position="static">
        <ThemeProvider theme={theTheme}>  

        <Container maxWidth="xl">
          <Toolbar disableGutters>
            
            <Link  to="/">
              <SmartToyIcon fontSize="large" sx={{ marginRight: "0.5rem", color: "#64ffda"}}/>
            </Link>
  
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' },  }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>

                              
                <Menu
                color="secondary"
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },                                       
                  }}
                >
                
                {pages.map((page) => (
                  <MenuItem color="secondary" key={page.adress} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center"><Link style={{textDecoration: "none", color: "#64ffda"}} to={`/${page.adress}`}>{page.title} </Link> </Typography>
                  </MenuItem>
                ))}
              </Menu>

              
               
              
            </Box>


            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                  <Link key={`link${page.adress}`} style={{textDecoration: "none", color:"#64ffda"}} to={`/${page.adress}`}>
                    <Button
                      color="secondary"
                      key={page}
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, display: 'block' }}
                    >
                      {page.title} 
                    </Button>
                  </Link>
                
              ))}
            </Box>
  
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar sx={{backgroundColor: "#64ffda"}}>
                     <FaceIcon sx={{color: "black", }} fontSize="large" />
                  </Avatar>
                </IconButton>
              </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem key="download resume" onClick={handleCloseResume} >
                     <Typography sx={{color: "#64ffda"}} textAlign="center">Resume</Typography>
                  </MenuItem>

                  {settings.map((setting) => (
                    <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                     <a key={setting.name} style={{textDecoration: "none"}} href={setting.link}> <Typography sx={{color: "#64ffda"}} textAlign="center">{setting.name}</Typography></a>
                    </MenuItem>
                  ))}
                 
                </Menu>
              
                  <DialogCv  setOpen={setOpen} open={open}/>

            </Box>
          </Toolbar>
        </Container>

        </ThemeProvider>
    </AppBar>
    )

} export default Navbar;