import { Box, useTheme, Link, Typography } from '@mui/material';
import React from 'react';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import useMediaQuery from '@mui/material/useMediaQuery';
import LinkIcon from '@mui/icons-material/Link';

function WarzoneWeapons(props) {
    

    const itemData = [
        {img: "/images/warzoneweapons/wzw1.jpeg" ,
        title: "warzoneweapons 1"},

        {img: "/images/warzoneweapons/wzw2.jpeg" ,
            title: "warzoneweapons 2 "},

        {img: "/images/warzoneweapons/wzw3.jpeg" ,
            title: "warzoneweapons 3 "},
        
       
        {img: "/images/warzoneweapons/wzw4.jpeg" ,
            title: "warzoneweapons 4"},
            
    
            
    ]

    const themeForBreakpoints = useTheme();
    const myCols = useMediaQuery(themeForBreakpoints.breakpoints.up('sm')) ? 2 : 1  ;



    return(
        <Box >

            <Typography sx={{textAlign:"center", color: "#64ffda", fontWeight:500, fontSize:{xs: "200%"}}} variant="h3">
                        Warzone Weapons Project
            </Typography>
            
            <Box  sx={{display:"flex", flexWrap: "wrap", justifyContent: "center", alignItems: "flex-start", marginTop:"3rem", marginBottom: "1.5rem"}} >              
                <Box sx={{  display:"flex", flexDirection:"column", justifyContent: "flex-start", width: {xs : "100%", sm: "70%", md: "70%" , lg: "50%"}}} >
                
                    <Typography sx={{color: "#8892b0", fontWeight:600, marginTop: "2rem"}}>
                        Stack: The app is a handmade RESTful API developed in Ruby on Rails for the back end. The Front  End is a ReactJs App contained
                        inside the ROR app thanks to a custom made webPacker. There are also multiple admin tools created to accurately enter large volumes of data.        
                    </Typography>
                    

                    <Typography sx={{color: "#8892b0", fontWeight:600, marginTop: "2rem"}}>
                        There is also a simple blog homepage made in ruby and HTML for SEO purposes. Sadly the amount of work needed to keep the data up to date was more than my 2 associates imagined. It was impossible for them to combine their streaming activities and keep the data up to date. 
                        After that, we decided to stop the project for the time being. Maybe when Warzone 2 is released in 2023 I may resume the work on the app but make the data gathering community activity.               
                    </Typography>

                    <Typography sx={{color: "#8892b0", fontWeight:600, marginTop: "2rem"}}>
                        Warzone Weapons is a web application that I started developing in 2019-2020. As Warzone became the most played game in the world during the Pandemic, 
                        I joined 2 Professional Streamers to develop a website for Warzone. The objective of the App is to create content and data on the "weapons" and "attachment" used in Warzone. 
                    </Typography> 

                    <Typography sx={{color: "#8892b0", fontWeight:600, marginTop: "2rem"}}>
                        To create the most engaging and valuable content, the app manages thousands of weapons characteristics modified in real-time by the attachments selection. 
                        The App also render dynamically numerous Graphs depending on the different options chosen by the user.
                    </Typography>

                </Box>
                
                <Box sx={{ marginTop:"1rem",  width: {xs : "100%", sm: "70%", md: "70%" , lg: "49%"} }}>
                    <ImageList sx={{ width: "100%", height: 600 }} cols={myCols}  rowHeight="auto">
                        {itemData.map((item) => (
                            <ImageListItem sx={{marginBottom:"1 rem" , border : "solid 1px #64ffda"}} key={item.img}>
                                <img
                                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.title}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>           
            </Box>

            <Link underline="hover"  sx={{marginTop: "5rem", textAlign:"center", color: "#64ffda", fontWeight:500, display:"flex", alignItems:"center"}} href="https://warzoneweapons.com/"> <LinkIcon/> Warzone Weapons </Link>
        </Box>
    )
} export default WarzoneWeapons;