import { Box, Typography, Link, Divider} from '@mui/material';
import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import LinkIcon from '@mui/icons-material/Link';

function ColorsPalette(props) {

    
    

       
    const itemData = [
        {img: "/images/colorspalette/palette1.jpeg" ,
        title: "colors 1"},

        {img: "/images/colorspalette/palette2.jpeg" ,
            title: "colors 2"},

        {img: "/images/colorspalette/palette3.jpeg" ,
            title: "colors 3"},
        
       
        {img: "/images/colorspalette/palette4.jpeg" ,
            title: "colors 4"},       
            
    ]





    return(
        
         <Box  sx={{ display:"flex", flexWrap: "wrap", justifyContent: "center", alignItems: "flex-start", marginTop:"3rem", marginBottom: "1.5rem"}} >

            <Typography variant="h4" textAlign="center" sx={{ color: "#64ffda", fontWeight:600, marginTop: "2rem",  width:"70%"}}>
            Colors Palettes Creator
            </Typography>  


            <Box sx={{  display:"flex", flexDirection:"column", justifyContent: "flex-start", width: {xs : "100%", sm: "90%", md: "90%" , lg: "80%"}}} >
                
                
                <Typography variant="block1" sx={{color: "#8892b0", fontWeight:600, marginTop: "2rem"}}>               
                    ReactJs Web Application. Allow the creation of Colors Palettes, generate different values of each colour, palette creation is draggable.
                    The App is styled with Material UI and Styled Component. All palettes are saved in local storage on the device used. T
                    he app in production was linked to an API and physical machine for printing colours samples tiles (but wasn't implicated). 
                    edit: these days I am updating the App for modern standards (Hooks only, Mui v5, React Rooter Dom v6 )
                </Typography> 


                <Link underline="hover"  sx={{ textAlign:"center", color: "#64ffda", fontWeight:500, display:"flex", alignItems:"center", marginTop: "2rem"}} href="https://colorjohnzermati.herokuapp.com/"> <LinkIcon/>Colors Palettes Creator </Link>
               
                <Typography variant="caption" sx={{color: "#8892b0", fontWeight:600}}>

                    (ps : The app is in sleep mode and need a good 30 secondes to wake up)

                </Typography>
            </Box>
                
            <Box sx={{ marginTop:"1rem",  width: {xs : "100%", sm: "95%", md: "95%" , lg: "95%"} }}>
                <ImageList variant="standard" cols={4}  rowHeight={300}>
                    {itemData.map((item) => (
                        <ImageListItem sx={{marginBottom:"1 rem" , border : "solid 1px #64ffda"}} key={item.img}>
                            <img
                                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt={item.title}
                                loading="lazy"
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>   

            <Divider variant="middle" />
            <Divider variant="middle" />
            <Divider variant="middle" />
            <Divider variant="middle" />

        </Box>

       
    
    )
} export default ColorsPalette;