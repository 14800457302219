import { Divider, List, ListItem } from '@mui/material';
import React from 'react';
import BattletomeAssistant from './BattleTomeAssistant';
import ColorsPalette from './OtherProject/ColorsPalette';
import Dogsadoptions from './OtherProject/DogsAdoptions';
import LightsOut from './OtherProject/LightsOut';
import WarOfSigmar from './WarOfSigmar';
import WarzoneWeapons from './WarzoneWeapons';


function Projects(props) {

    const dividerColor = "#64ffda"
    
    return(
        <List>

            <ListItem>
                <WarzoneWeapons/>
            </ListItem>
                <Divider sx={{margin: "1rem"}} color={dividerColor}  />

            <ListItem>
                <BattletomeAssistant/>
            </ListItem>
                <Divider  sx={{margin: "1rem"}}  color={dividerColor}  />

            <ListItem>
                <WarOfSigmar/>
            </ListItem>
                 <Divider  sx={{margin: "1rem"}}  color={dividerColor}  />

            <ListItem>
                <ColorsPalette />
            </ListItem>
                <Divider  sx={{margin: "1rem"}}  color={dividerColor}  />

            <ListItem>
                <Dogsadoptions />
            </ListItem>
                <Divider  sx={{margin: "1rem"}}  color={dividerColor}  />

            <ListItem>
                <LightsOut />
            </ListItem>
                 <Divider  sx={{margin: "1rem"}}  color={dividerColor}  />


        </List>
    )

} export default Projects